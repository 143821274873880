import { helpers } from "@contentful/rich-text-types";
import {
  defaultNodeRenderers,
  defaultMarkRenderers
} from "@utils/richTextRenderers";

/**
 * Return an array of render functions
 * @param {array} nodes
 * @param {string} key
 * @param {object} renderer
 */
const renderNodeList = (nodes, key, renderer) =>
  nodes.map((node, i) => renderNode(node, `${key}-${i}`, renderer));

/**
 * Compile correct function
 * @param {array} node
 * @param {string} key
 * @param {object} renderer
 */
const renderNode = (node, key, renderer) => {
  const { nodeRenderer, markRenderer, h } = renderer;

  // These require the mark renderers
  if (helpers.isText(node)) {
    return nodeRenderer.text(node, key, h, markRenderer);
  }

  // Recursively render nested arrays
  const next = nodes => renderNodeList(nodes, key, renderer);

  // Return node of render functions
  return nodeRenderer[node.nodeType](node, key, h, next);
};

/**
 * Component
 */
export default {
  name: "CoreRichText",

  props: {
    document: {
      type: Object,
      default: () => ({
        content: []
      })
    },
    nodeRenderers: {
      type: Object,
      default: () => ({})
    },
    markRenderers: {
      type: Object,
      default: () => ({})
    }
  },

  render(h) {
    const { nodeRenderers, markRenderers } = this;

    const renderer = {
      nodeRenderer: {
        ...defaultNodeRenderers,
        ...nodeRenderers
      },
      markRenderer: {
        ...defaultMarkRenderers,
        ...markRenderers
      },
      h
    };

    return h(
      "div",
      {
        class: "whitespace-pre-wrap overflow-break"
      },
      renderNodeList(this.document.content, "CoreRichText", renderer)
    );
  }
};
