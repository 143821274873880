import {
  getEntries,
  getEntryBySlug,
  filterFields,
  filterItems
} from "./contentful";

const CONTENT_TYPE_ID = "competition";

export const getCompetitions = async () => {
  let response = await getEntries(CONTENT_TYPE_ID);
  response = filterItems(response);
  response = filterFields(response);

  return response;
};

export const getCompetition = async slug => {
  let response = await getEntryBySlug(CONTENT_TYPE_ID, slug);
  response = filterItems(response);
  response = filterFields(response);

  return response[0];
};
