<template>
  <div class="flex flex-col flex-grow">
    <BlockHeader />
    <BlockNav />
    <div class="flex-grow flex flex-col">
      <slot />
    </div>
    <BlockFooter />
  </div>
</template>

<script>
import BlockHeader from "./BlockHeader";
import BlockNav from "./BlockNav";
import BlockFooter from "./BlockFooter";

export default {
  name: "BlockContainer",

  components: {
    BlockHeader,
    BlockNav,
    BlockFooter
  }
};
</script>
