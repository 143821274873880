<template>
  <div
    ref="zoomerContainer"
    class="overflow-hidden"
    @click="handleClick"
    :class="[over ? 'cursor-zoom-out' : 'cursor-zoom-in']"
  >
    <CoreImage :imgStyle="zoomerStyle" imgClass="w-full" :image="image" />
  </div>
</template>

<script>
import { bind, unbind } from "@utils/eventListener";

export default {
  name: "CoreImageZoomer",

  props: {
    image: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    x: 0,
    y: 0,
    over: false
  }),

  computed: {
    zoomerStyle() {
      return {
        ...(this.over && { transform: "scale(1.5)" }),
        transformOrigin: `${this.x}% ${this.y}%`
      };
    }
  },

  mounted() {
    bind(this.$refs.zoomerContainer, "mousemove", this.handleMousemove);
    // bind(this.$refs.zoomerContainer, "mouseover", this.handleMouseover);
    // bind(this.$refs.zoomerContainer, "mouseout", this.handleMouseout);
  },

  beforeDestroy() {
    unbind(this.$refs.zoomerContainer, "mousemove", this.handleMousemove);
    // unbind(this.$refs.zoomerContainer, "mouseover", this.handleMouseover);
    // unbind(this.$refs.zoomerContainer, "mouseout", this.handleMouseout);
  },

  methods: {
    handleClick() {
      this.over = !this.over;
    },
    handleMousemove(e) {
      const {
        top,
        bottom,
        left,
        right
      } = this.$refs.zoomerContainer.getBoundingClientRect();

      this.x = ((e.clientX - left) / (right - left)) * 100;
      this.y = ((e.clientY - top) / (bottom - top)) * 100;
    }
    // handleMouseover() {
    //   this.over = true;
    // },
    // handleMouseout() {
    //   this.over = false;
    // }
  }
};
</script>

<style>
.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}
</style>
