<template>
  <picture>
    <source :srcset="webp" :sizes="sizes" type="image/webp" />
    <source :srcset="jpeg" :sizes="sizes" type="image/jpeg" />
    <img
      :srcset="this.image.fields.file.url"
      :alt="image.fields.title"
      :class="imgClass"
      :style="imgStyle"
    />
  </picture>
</template>

<script>
const transformSizes = [2560, 1920, 1600, 1366, 1024, 768, 640];

export default {
  name: "CoreImage",

  props: {
    image: {
      type: Object,
      default: () => ({})
    },
    imgClass: {
      type: String,
      default: ""
    },
    imgStyle: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    webp() {
      const q = 80;
      return transformSizes.map(
        w => `${this.image.fields.file.url}?w=${w}&fm=webp&q=${q} ${w}w`
      );
    },
    jpeg() {
      const q = 80;
      return transformSizes.map(
        w => `${this.image.fields.file.url}?w=${w}&fm=jpg&q=${q} ${w}w`
      );
    },
    sizes() {
      const orderedSizes = [...transformSizes].reverse();
      if (this.optimiseImages) {
        return [
          ...orderedSizes.map(w => `(max-width: ${w}px) ${w}px`),
          `${transformSizes[0]}px`
        ];
      }

      return false;
    },
    fallback() {
      return this.image.fields.file.url;
    }
  }
};
</script>
