import { firebase } from "@/firebase.config";
import {
  getEntries,
  getEntryBySlug,
  filterItems,
  filterFields
} from "./contentful";

const CONTENT_TYPE_ID = "product";

export const getProducts = async () => {
  let response = await getEntries(CONTENT_TYPE_ID);
  response = filterItems(response);
  response = filterFields(response);

  return response;
};

export const getProduct = async slug => {
  let response = await getEntryBySlug(CONTENT_TYPE_ID, slug);
  response = filterItems(response);
  response = filterFields(response);

  return response[0];
};

export const submitApplication = async formData => {
  return firebase.functions().httpsCallable("submitApplication")(formData);
};
