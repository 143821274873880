<template>
  <div class="w-full md:w-1/3 p-4 cursor-pointer" @click="$emit('click')">
    <CoreImage
      class="image-size"
      :image="heroImage"
      imgClass="image-size-inner"
    />
    <h2 class="mt-2 text-base">{{ title }}</h2>
    <p class="text-sm text-gray">{{ formattedDate }}</p>
  </div>
</template>

<script>
export default {
  name: "BlockWideThumbnail",

  props: {
    heroImage: {
      type: Object
    },
    title: {
      type: String,
      default: ""
    },
    date: {
      type: String,
      default: ""
    },
    dateFormater: {
      type: Function,
      default: null
    }
  },

  computed: {
    formattedDate() {
      if (this.dateFormater) {
        return this.dateFormater(this.date);
      }

      return new Date(this.date).toLocaleString("en-GB", { dateStyle: "long" });
    }
  }
};
</script>

<style scoped>
.image-size {
  height: 0;
  width: 100%;
  padding-bottom: 75%;
  overflow: hidden;
  position: relative;
  display: block;
}

::v-deep .image-size-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
