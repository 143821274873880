export default {
  name: "CoreDynamicInput",
  props: {
    inputType: {
      validator: value => ["input", "textarea"].indexOf(value) !== -1,
      default: "input"
    },
    value: {
      type: String,
      default: ""
    }
  },
  render(createElement) {
    return createElement(
      this.inputType, // tag name
      {
        domProps: {
          value: this.value
        }
      }
    );
  }
};
