import {
  getEntries,
  getEntryBySlug,
  filterItems,
  filterFields
} from "./contentful";

const CONTENT_TYPE_ID = "blogPosts";

export const getPosts = async () => {
  let response = await getEntries(CONTENT_TYPE_ID);
  response = filterItems(response);
  response = filterFields(response);

  return response;
};

export const getPost = async slug => {
  let response = await getEntryBySlug(CONTENT_TYPE_ID, slug);
  response = filterItems(response);
  response = filterFields(response);

  return response[0];
};
