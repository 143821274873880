import {
  getEntries,
  filterLatest,
  filterFields,
  filterItems,
} from './contentful';

const CONTENT_TYPE_ID = 'contactUs';

export const get = async () => {
  let response = await getEntries(CONTENT_TYPE_ID);

  response = filterItems(response);
  response = filterFields(response);
  response = filterLatest(response);

  return response;
};
