import Vue from "vue";

export default {
  namespaced: true,
  state: () => ({
    data: {}
  }),
  actions: {
    addState({ state }, { key, value }) {
      Vue.set(state.data, key, value);
    }
  }
};
