<template>
  <div class="relative pb-video">
    <iframe
      v-if="host === 'youtube'"
      class="absolute"
      width="100%"
      height="100%"
      :src="`https://www.youtube.com/embed/${youtubeId}`"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
    <div
      v-if="host === 'vimeo'"
      ref="viemo"
      class="absolute w-full h-full"
      v-html="vimeoOEmbed.html"
    />
  </div>
</template>

<script>
export default {
  name: "BlockVimeo",

  props: {
    uri: {
      type: String
    }
  },

  data: () => ({
    vimeoOEmbed: {}
  }),

  computed: {
    host() {
      const { hostname } = new URL(this.uri);

      if (hostname.includes("youtube") || hostname.includes("youtu.be"))
        return "youtube";
      if (hostname.includes("vimeo")) return "vimeo";

      return "";
    },
    youtubeId() {
      // eslint-disable-next-line no-useless-escape
      const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = this.uri.match(regExp);
      if (match && match[2].length == 11) return match[2];
      return "";
    }
  },

  watch: {
    uri: {
      immediate: true,
      async handler() {
        if (this.host !== "vimeo") return "";
        const response = await fetch(
          `https://vimeo.com/api/oembed.json?url=${this.uri}`,
          {
            method: "GET"
          }
        );

        const oEmbed = await response.json();

        this.vimeoOEmbed = oEmbed;

        await this.$nextTick();

        this.$refs.viemo.firstChild.width = "100%";
        this.$refs.viemo.firstChild.height = "100%";
      }
    }
  }
};
</script>

<style scoped>
.pb-video {
  padding-bottom: 56.25%;
}
</style>
