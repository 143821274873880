import { getEntries, filterItems, filterFields } from "./contentful";

const CONTENT_TYPE_ID = "newsArticle";

export const get = async () => {
  let response = await getEntries(CONTENT_TYPE_ID);
  response = filterItems(response);
  response = filterFields(response);

  return response;
};
