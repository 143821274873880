<template>
  <video>
    <source :src="video.fields.file.url" type="video/mp4" />
  </video>
</template>

<script>
export default {
  name: "CoreVideo",

  props: {
    video: {
      type: Object
    }
  }
};
</script>
