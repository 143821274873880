import Home from '@views/Home';

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@views/About'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '@views/Blog'),
  },
  {
    path: '/blog/:slug',
    name: 'BlogItem',
    component: () => import(/* webpackChunkName: "blog" */ '@views/BlogItem'),
  },
  {
    path: '/competition',
    name: 'Competition',
    component: () =>
      import(/* webpackChunkName: "competition" */ '@views/Competition'),
  },
  {
    path: '/competition/:slug',
    name: 'CompetitionItem',
    component: () =>
      import(/* webpackChunkName: "blog" */ '@views/CompetitionItem'),
  },
  {
    path: '/diamonds',
    name: 'Diamonds',
    component: () =>
      import(/* webpackChunkName: "diamonds" */ '@views/Diamonds'),
  },
  {
    path: '/jewellery',
    name: 'Jewellery',
    component: () =>
      import(/* webpackChunkName: "jewellery" */ '@views/Jewellery'),
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '@views/News'),
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '@views/Shop'),
  },
  {
    path: '/shop/:slug',
    name: 'ShopItem',
    component: () => import(/* webpackChunkName: "shop" */ '@views/ShopItem'),
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "about" */ '@views/Contact'),
  },
];
