<template>
  <div id="g-recaptcha" :data-sitekey="sitekey" data-size="invisible" />
</template>

<script>
// Recapture is imported on the window

export default {
  name: "CoreRecapcha",

  data: () => ({
    sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    widgetId: 0,
    resolve: null
  }),

  mounted() {
    this.scriptAvalible();
  },

  methods: {
    async execute() {
      const response = await new Promise(resolve => {
        this.resolve = resolve;
        window.grecaptcha.execute(this.widgetId);
      });

      return response;
    },
    reset() {
      window.grecaptcha.reset(this.widgetId);
    },
    scriptAvalible() {
      if (window.grecaptcha.render) this.render();
      else setTimeout(this.scriptAvalible, 1000);
    },
    render() {
      this.widgetId = window.grecaptcha.render("g-recaptcha", {
        sitekey: this.sitekey,
        size: "invisible",
        callback: response => {
          this.reset();
          this.resolve(response);
        }
      });
    }
  }
};
</script>
