import Vue from "vue";

// Add mixins
import uuid from "@mixins/uuid";

import recaptcha from "./vendor/recaptcha";

// Add core components
import "@c/Core";

// Firebase
import { firebase, app, analytics, perf } from "./firebase.config";

Vue.mixin(uuid);

// Load recaptcha
recaptcha();

Vue.prototype.$firebase = {
  firebase,
  app,
  analytics,
  perf
};

Vue.config.productionTip = false;
