/**
 *
 * @param {Element} el
 * @param {mixed} event
 * @param {Function} handler
 * @param {Boolean} action
 */
const bindingHandler = (el, event, handler, action) => {
  const events = Array.isArray(event) ? event : String(event).split(" ");
  events.forEach(e => {
    el[`${action ? "add" : "remove"}EventListener`](e, handler, false);
  });
};

export const bind = (el, event, handler) =>
  bindingHandler(el, event, handler, true);

export const unbind = (el, event, handler) =>
  bindingHandler(el, event, handler, false);
