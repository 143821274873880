import isMobile from "@utils/isMobile";

export default {
  state: () => ({
    backgroundWhite: false,
    isMobile: true
  }),
  mutations: {
    setBackgroundWhite(state, payload) {
      state.backgroundWhite = payload;
    },
    setIsMobile(state, payload) {
      state.isMobile = payload;
    }
  },
  actions: {
    setBackgroundWhite({ commit }, payload) {
      document.body.classList.remove(payload ? "bg-black" : "bg-white");
      document.body.classList.add(payload ? "bg-white" : "bg-black");
      commit("setBackgroundWhite", payload);
    },
    setIsMobile({ commit }) {
      commit("setIsMobile", isMobile());
    }
  }
};
