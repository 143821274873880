<template>
  <CoreBar
    class="flex justify-between md:justify-center items-center sticky z-50 bg-inherit top-0 left-0 md:static"
  >
    <nav
      class="flex text-lg transition-menu"
      :class="[
        {
          'flex-col fixed h-menu w-full left-0 bg-inherit justify-center items-center': isMobile,
          'opacity-1': isMobile && open,
          'opacity-0 pointer-events-none': isMobile && !open,
        },
      ]"
    >
      <div class="hidden absolute top-0 mt-4 md:hidden">
        <CoreLogo class="w-24" />
      </div>
      <div class="flex flex-col md:flex-row gap-2 md:gap-6 text-center items-center justify-center">
        <RouterLink
          :class="{ 'link-active': routeActive('About') }"
          :to="{ name: 'About' }"
        >
          ABOUT
        </RouterLink>
        <RouterLink
          :class="{ 'link-active': routeActive('Diamonds') }"
          :to="{ name: 'Diamonds' }"
        >
          DIAMONDS
        </RouterLink>
        <RouterLink
          :class="{ 'link-active': routeActive('Jewellery') }"
          :to="{ name: 'Jewellery' }"
        >
          JEWELLERY
        </RouterLink>
        <RouterLink
          :class="{ 'link-active': routeActive('Shop') }"
          :to="{ name: 'Shop' }"
        >
          SHOP
        </RouterLink>
        <RouterLink
          :class="{ 'link-active': routeActive('Blog') }"
          :to="{ name: 'Blog' }"
        >
          BLOG
        </RouterLink>
        <RouterLink
          v-if="false"
          :class="{ 'link-active': routeActive('Competition') }"
          :to="{ name: 'Competition' }"
        >
          COMPETITION
        </RouterLink>
        <RouterLink
          :class="{ 'link-active': routeActive('ContactUs') }"
          :to="{ name: 'ContactUs' }"
        >
          CONTACT US
        </RouterLink>
      </div>
    </nav>
    <CoreLogotype class="mx-6 text-xl md:hidden" />
    <CoreBurger
      class="mx-8"
      v-if="isMobile"
      :open="open"
      @click.native="open = !open"
    />
  </CoreBar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BlockNav',

  data: () => ({
    open: false,
  }),

  computed: {
    ...mapState({
      isMobile: state => state.global.isMobile,
    }),
  },

  watch: {
    isMobile() {
      this.open = false;
    },
    open: {
      immediate: true,
      handler(open) {
        document.body.classList[open ? 'add' : 'remove']('overflow-hidden');
      },
    },
  },

  created() {
    // This essentually handles route change rendering
    this.open = false;
  },

  methods: {
    routeActive(routeName) {
      return this.$route.name.includes(routeName);
    },
  },
};
</script>

<style scoped>
.h-menu {
  height: calc(100% - 4rem);
  top: 4rem;
}

.transition-menu {
  transition: 0.3s opacity cubic-bezier(0.22, 1, 0.36, 1);
}
</style>
