import { render, staticRenderFns } from "./BlockGradient.vue?vue&type=template&id=576867ad&scoped=true"
import script from "./BlockGradient.vue?vue&type=script&lang=js"
export * from "./BlockGradient.vue?vue&type=script&lang=js"
import style0 from "./BlockGradient.vue?vue&type=style&index=0&id=576867ad&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576867ad",
  null
  
)

export default component.exports