<template>
  <img :src="iconPath" />
</template>

<script>
const instagram = require("@assets/icons/instagram-brands.svg");
const linkedin = require("@assets/icons/linkedin-in-brands.svg");
const pintrest = require("@assets/icons/pinterest-brands.svg");
const facebook = require("@assets/icons/facebook-f-brands.svg");
const download = require("@assets/icons/download-solid.svg");

const iconMap = {
  instagram,
  linkedin,
  pintrest,
  facebook,
  download
};

export default {
  name: "CoreIcon",

  props: {
    icon: {
      validator: value =>
        [
          null,
          "instagram",
          "linkedin",
          "pintrest",
          "facebook",
          "download"
        ].indexOf(value) !== -1,
      default: null
    }
  },

  computed: {
    iconPath() {
      return this.icon ? iconMap[this.icon] : "";
    }
  }
};
</script>

<style scoped>
.bg-black img {
  filter: invert(1);
}
</style>
