module.exports = {
  purge: ["./src/**/*.vue"],
  theme: {
    extend: {
      height: {
        "screen-1/2": "50vh"
      },
      width: {
        "screen-1/2": "50vw"
      }
    },
    screens: {
      sm: "640px", // => @media (min-width: 640px) { ... }
      md: "768px", // => @media (min-width: 768px) { ... }
      lg: "1024px", // => @media (min-width: 1024px) { ... }
      xl: "1280px" // => @media (min-width: 1280px) { ... }
    },
    colors: {
      black: "#000000",
      white: "#FFFFFF",
      gray: "#838383",
      red: "#FF0000"
    }
  },
  variants: {},
  plugins: []
};
