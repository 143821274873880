<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import { bind } from "@utils/eventListener";

export default {
  name: "App",

  created() {
    this.$store.dispatch("setIsMobile");
    bind(
      window,
      "resize",
      debounce(() => this.$store.dispatch("setIsMobile"), 300)
    );
  }
};
</script>

<style src="@assets/css/index.css" />
