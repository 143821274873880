import { theme } from "@root/tailwind.config";

export const screens = (key = null) => {
  if (key) return theme.screens[key];
  else {
    return Object.entries(theme.screens)
      .sort(([, a], [, b]) => (parseInt(a) > parseInt(b) ? 1 : -1))
      .map(([key, value]) => ({ handle: key, width: value }));
  }
};
