import Vue from "vue";

import CoreImage from "./CoreImage";
import CoreLogo from "./CoreLogo";
import CoreBurger from "./CoreBurger";
import CoreBar from "./CoreBar";
import CoreLogotype from "./CoreLogotype";
import CoreRichText from "./CoreRichText";
import CoreIcon from "./CoreIcon";
import CoreImageZoomer from "./CoreImageZoomer";
import CoreVideo from "./CoreVideo";
import CoreInput from "./CoreInput";
import CoreDynamicInput from "./CoreDynamicInput";
import CoreRecapcha from "./CoreRecapcha";

const core = [
  CoreImage,
  CoreLogo,
  CoreBurger,
  CoreBar,
  CoreLogotype,
  CoreRichText,
  CoreIcon,
  CoreImageZoomer,
  CoreVideo,
  CoreInput,
  CoreDynamicInput,
  CoreRecapcha
];

core.forEach(component => Vue.component(component.name, component));
