<template>
  <div
    ref="container"
    class="flex flex-col relative prefix"
    :class="{ 'prefix-on': !!internalValue }"
    :data-prefix="prefix"
  >
    <div class="flex">
      <label
        class="text-xs mb-1 text-black inline"
        :class="{ required }"
        :for="uuid"
        v-text="label"
      />
      <span v-show="error" class="inline text-xs text-red ml-2">
        {{ errorMessage }}</span
      >
    </div>
    <CoreDynamicInput
      :id="uuid"
      :input-type="inputType"
      class="input-area px-2 py-2 mb-6 text-base leading-none"
      :style="style"
      :class="{ error }"
      :type="type"
      :required="required"
      :disabled="disabled"
      :rows="inputType === 'textarea' && rows"
      :value="value"
      @keydown.native="keydown"
      @paste.native="disablePaste && $event.preventDefault()"
      @input.native="$emit('update:value', $event.target.value)"
      @focus.native="$emit('focus')"
      @blur.native="$emit('blur', { ...$event, interacted })"
    />
  </div>
</template>

<script>
export default {
  name: "CoreInput",

  props: {
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    inputType: {
      type: String,
      default: "input"
    },
    rows: {
      type: Number,
      default: 5
    },
    type: {
      type: String,
      default: "text"
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    prefix: {
      type: String,
      default: ""
    },
    validator: {
      type: Function,
      default: () => true
    },
    disablePaste: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    psudoWidth: "0px",
    internalValue: "",
    interacted: false
  }),

  computed: {
    style() {
      return {
        ...(this.psudoWidth !== "0px" && {
          paddingLeft: `calc(0.75rem + ${this.psudoWidth})`
        })
      };
    }
  },

  mounted() {
    this.psudoWidth = window.getComputedStyle(
      this.$refs.container,
      ":before"
    ).width;
  },

  methods: {
    keydown(e) {
      this.internalValue = e.target.value;
      if (!this.validator(e.target.value, e)) e.preventDefault();
      this.interacted = true;
    }
  }
};
</script>

<style scoped>
.prefix:before {
  content: attr(data-prefix);
  @apply text-base leading-tight;
  color: theme(colors.gray);
  position: absolute;
  margin-top: 2.125rem; /* calculated height of elements to perfect position */
  margin-left: 0.5rem;
  transition-property: color;
  transition-duration: var(--transition-short);
  transition-timing-function: var(--transition-ease);
  user-select: none;
}

.prefix-on:before {
  color: theme(colors.black);
}

.prefix:focus-within:before {
  color: theme(colors.black);
}

.required:after {
  content: " *";
}

/* .input-area::v-deep:-webkit-autofill,
.input-area::v-deep:-webkit-autofill:hover,
.input-area::v-deep:-webkit-autofill:focus,
.input-area::v-deep:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0px 1000px theme(colors.white) inset;
} */

.input-area::v-deep {
  border: 1px solid theme(colors.gray);
  transition-property: border, color, background-color;
  transition-duration: var(--transition-long);
  transition-timing-function: var(--transition-ease);
}

.error {
  border: 1px solid theme(colors.red);
  color: theme(colors.red);
}

.input-area::v-deep:focus {
  outline: none;
  color: theme(colors.black);
  border: 1px solid theme(colors.black);
}

.input-area::v-deep:disabled {
  cursor: default;
  background-color: theme(colors.gray);
  border: 1px solid theme(colors.gray);
}

/* .input-area::v-deep:-webkit-autofill:disabled,
.input-area::v-deep:-internal-autofill-selected:disabled {
  transition-property: border, color, background-color, -webkit-box-shadow;
  -webkit-box-shadow: 0 0 0px 1000px theme(colors.gray) inset;
} */
</style>
