var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CoreBar',{staticClass:"flex justify-between md:justify-center items-center sticky z-50 bg-inherit top-0 left-0 md:static"},[_c('nav',{staticClass:"flex text-lg transition-menu",class:[
      {
        'flex-col fixed h-menu w-full left-0 bg-inherit justify-center items-center': _vm.isMobile,
        'opacity-1': _vm.isMobile && _vm.open,
        'opacity-0 pointer-events-none': _vm.isMobile && !_vm.open,
      },
    ]},[_c('div',{staticClass:"hidden absolute top-0 mt-4 md:hidden"},[_c('CoreLogo',{staticClass:"w-24"})],1),_c('div',{staticClass:"flex flex-col md:flex-row gap-2 md:gap-6 text-center items-center justify-center"},[_c('RouterLink',{class:{ 'link-active': _vm.routeActive('About') },attrs:{"to":{ name: 'About' }}},[_vm._v(" ABOUT ")]),_c('RouterLink',{class:{ 'link-active': _vm.routeActive('Diamonds') },attrs:{"to":{ name: 'Diamonds' }}},[_vm._v(" DIAMONDS ")]),_c('RouterLink',{class:{ 'link-active': _vm.routeActive('Jewellery') },attrs:{"to":{ name: 'Jewellery' }}},[_vm._v(" JEWELLERY ")]),_c('RouterLink',{class:{ 'link-active': _vm.routeActive('Shop') },attrs:{"to":{ name: 'Shop' }}},[_vm._v(" SHOP ")]),_c('RouterLink',{class:{ 'link-active': _vm.routeActive('Blog') },attrs:{"to":{ name: 'Blog' }}},[_vm._v(" BLOG ")]),(false)?_c('RouterLink',{class:{ 'link-active': _vm.routeActive('Competition') },attrs:{"to":{ name: 'Competition' }}},[_vm._v(" COMPETITION ")]):_vm._e(),_c('RouterLink',{class:{ 'link-active': _vm.routeActive('ContactUs') },attrs:{"to":{ name: 'ContactUs' }}},[_vm._v(" CONTACT US ")])],1)]),_c('CoreLogotype',{staticClass:"mx-6 text-xl md:hidden"}),(_vm.isMobile)?_c('CoreBurger',{staticClass:"mx-8",attrs:{"open":_vm.open},nativeOn:{"click":function($event){_vm.open = !_vm.open}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }