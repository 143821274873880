import store from "@store";
import {
  getEntries,
  filterLatest,
  filterFields,
  filterItems
} from "./contentful";

const CONTENT_TYPE_ID = "global";

let dataExists = false;

export const get = async (refresh = false) => {
  if (!dataExists || refresh) {
    let response = await getEntries(CONTENT_TYPE_ID);
    response = filterItems(response);
    response = filterFields(response);
    response = filterLatest(response);
    // Get data from API and set it in the store
    Object.entries(response).forEach(async ([key, value]) =>
      store.dispatch("io/global/addState", { key, value })
    );
  }

  return store.state.io.global.data;
};
