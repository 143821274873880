<template>
  <CoreImage
    class="border-b"
    imgClass="object-cover w-full h-screen-1/2"
    :image="image"
  />
</template>

<script>
export default {
  name: "BlockHero",

  props: {
    image: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
