<template>
  <div>
    <img
      v-if="!backgroundWhite"
      :src="require('@assets/images/header-black.jpg')"
    />
    <img
      v-if="backgroundWhite"
      :src="require('@assets/images/header-white.jpg')"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CoreLogo",

  computed: {
    ...mapState({
      backgroundWhite: state => state.global.backgroundWhite
    })
  }
};
</script>
