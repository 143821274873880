import "@assets/css/richText.css";

import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { isImage } from "./mime";
import { CONTENT_TYPE_TO_ROUTE_NAME_MAP } from "@const";
import { isValidUrl } from "@utils/types";

import { BlockEmbeddedVideo } from "@c/Block";

export { BLOCKS, MARKS, INLINES };

// Utils
export const fallback = (_, key, h) => h("span", { key });

export const elementGenerator = (elm, node, key, h, next) =>
  h(elm, { key }, next(node.content));

// Basic elements
export const paragraph = (...args) => elementGenerator("p", ...args);
export const heading1 = (...args) => elementGenerator("h1", ...args);
export const heading2 = (...args) => elementGenerator("h2", ...args);
export const heading3 = (...args) => elementGenerator("h3", ...args);
export const heading4 = (...args) => elementGenerator("h4", ...args);
export const heading5 = (...args) => elementGenerator("h5", ...args);
export const heading6 = (...args) => elementGenerator("h6", ...args);
export const div = (...args) => elementGenerator("div", ...args);
export const ul = (...args) => elementGenerator("ul", ...args);
export const ol = (...args) => elementGenerator("ol", ...args);
export const blockquote = (...args) => elementGenerator("blockquote", ...args);

export const hr = (_node, key, h) => h("hr", { key }, {});

export const li = (node, key, h, next) =>
  h(
    "li",
    { key, class: "list-disc list-inside inline-p-child" },
    next(node.content)
  );

export const hyperlink = uriPath => {
  return (node, key, h, next) => {
    // I don't like this design
    const uri = uriPath.split(".").reduce((obj, key) => obj[key], node);

    if (isValidUrl(uri)) {
      const { hostname } = new URL(uri);
      if (
        hostname.includes("youtube") ||
        hostname.includes("youtu.be") ||
        hostname.includes("vimeo")
      )
        return embeddedVideo(uri, key, h);
    }

    return h(
      "a",
      {
        key,
        attrs: {
          target: "_blank",
          href: uri,
          class: "link cursor-pointer"
        }
      },
      next(node.content)
    );
  };
};

// Custom elements
export const routerLink = (node, key, h) =>
  h(
    "RouterLink",
    {
      key,
      class: "link cursor-pointer",
      props: {
        to: {
          name:
            CONTENT_TYPE_TO_ROUTE_NAME_MAP[
              node.data.target.sys.contentType.sys.id
            ]
        }
      }
    },
    node.content[0].value
  );

export const embeddedAsset = (node, key, h) => {
  const { contentType } = node.data.target.fields.file;
  if (isImage(contentType)) {
    return h("CoreImage", {
      key,
      props: {
        image: node.data.target,
        imgClass: "w-full"
      }
    });
  }
};

export const embeddedVideo = (uri, key, h) => {
  return h(BlockEmbeddedVideo, {
    key,
    props: {
      uri
    }
  });
};

// Untested
// Node that uses the mark renderer
export const text = ({ marks, value }, key, h, markRenderer) => {
  if (!marks.length) {
    return value;
  }

  const marksReversed = [...marks].reverse();
  return marksReversed.reduce(
    (aggregate, mark, i) =>
      markRenderer[mark.type]([aggregate], `${key}-${i}`, h),
    value
  );
};

// Mark renderers
export const defaultMarkRenderer = (mark, children, key, h) =>
  h(mark, { key }, children);

export const bold = (...args) => defaultMarkRenderer("strong", ...args);
export const italic = (...args) => defaultMarkRenderer("em", ...args);
export const underline = (...args) => defaultMarkRenderer("u", ...args);
export const code = (...args) => defaultMarkRenderer("code", ...args);

// Default renderer objects
export const defaultNodeRenderers = {
  [BLOCKS.PARAGRAPH]: paragraph,
  [BLOCKS.HEADING_1]: heading1,
  [BLOCKS.HEADING_2]: heading2,
  [BLOCKS.HEADING_3]: heading3,
  [BLOCKS.HEADING_4]: heading4,
  [BLOCKS.HEADING_5]: heading5,
  [BLOCKS.HEADING_6]: heading6,
  [BLOCKS.EMBEDDED_ENTRY]: div,
  [BLOCKS.UL_LIST]: ul,
  [BLOCKS.OL_LIST]: ol,
  [BLOCKS.LIST_ITEM]: li,
  [BLOCKS.QUOTE]: blockquote,
  [BLOCKS.HR]: hr,
  [BLOCKS.EMBEDDED_ASSET]: embeddedAsset,
  [INLINES.ASSET_HYPERLINK]: hyperlink("data.target.fields.file.url"),
  [INLINES.ENTRY_HYPERLINK]: routerLink,
  // [INLINES.EMBEDDED_ENTRY]: ,
  [INLINES.HYPERLINK]: hyperlink("data.uri"),
  text
};

export const defaultMarkRenderers = {
  [MARKS.BOLD]: bold,
  [MARKS.ITALIC]: italic,
  [MARKS.UNDERLINE]: underline,
  [MARKS.CODE]: code
};
