<template>
  <div class="bg-white gradient" :class="{ 'gradient-reverse': reverse }">
    <div class="flex relative" :class="{ 'flex-row-reverse': reverse }">
      <div class="w-full md:w-1/2"><slot /></div>
      <div class="hidden md:block w-1/2" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockGradient",

  props: {
    alignment: {
      default: "left",
      validator: value => ["left", "right"].indexOf(value) !== -1
    }
  },

  computed: {
    reverse() {
      return this.alignment === "right";
    }
  }
};
</script>

<style scoped>
@media (min-width: 768px) {
  .gradient {
    position: relative;

    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(74, 74, 74, 1) 100%
    );
  }

  .gradient:before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.3s opacity cubic-bezier(0.22, 1, 0.36, 1);

    background: linear-gradient(
      90deg,
      rgba(74, 74, 74, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .gradient-reverse:before {
    opacity: 1;
  }
}
</style>
