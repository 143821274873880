<template>
  <div class="h-16 border-b">
    <slot />
  </div>
</template>

<script>
export default {
  name: "CoreBar"
};
</script>
