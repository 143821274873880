import { createClient } from "contentful";

const urlParams = new URLSearchParams(window.location.search);
const preview = urlParams.get("preview") === "1";

const client = createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: preview
    ? process.env.VUE_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN
    : process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
  ...(preview && { host: "preview.contentful.com" })
});

/**
 * Get an entry by ID
 * @param {String} id
 */
export const getEntry = async id => {
  return await client.getEntry(id);
};

/**
 * Get an entry by Slug
 * @param {String} id
 */
export const getEntryBySlug = async (content_type, slug) => {
  return await client.getEntries({
    content_type,
    "fields.slug[in]": slug
  });
};

/**
 * Get an entry by ID
 * @param {String} content_type
 */
export const getEntries = async content_type => {
  return await client.getEntries({ content_type });
};

/**
 * return fields
 * @param {Object} response
 */
// export const filterFields = response => response.fields;
export const filterFields = response => response.map(items => items.fields);

/**
 * return latest entry
 * @param {Object} response
 */
export const filterLatest = response => response[0];

/**
 * return latest entry
 * @param {Object} response
 */
export const filterItems = response => response.items;
