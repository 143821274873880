<template>
  <div class="flex-grow flex flex-col">
    <BlockNav />
    <div
      class="flex-grow flex flex-col justify-center items-center select-none"
    >
      <CoreLogotype class="text-lg md:text-2xl text-center mb-4 mt-8" />
      <CoreLogo class="w-logo" />
    </div>
    <BlockFooter />
  </div>
</template>

<script>
import { BlockNav, BlockFooter } from "@c/Block";

export default {
  name: "Home",

  components: {
    BlockNav,
    BlockFooter
  }
};
</script>

<style scoped>
.w-logo {
  max-width: 360px;
  margin-bottom: 5%;
}

@media (min-width: 768px) {
  .w-logo {
    max-width: 800px;
  }
}
</style>
