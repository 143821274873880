<template>
  <footer
    class="border-t text-base flex flex-wrap justify-between px-4 pb-4 bg-black"
  >
    <template v-if="!!data">
      <div class="flex flex-wrap">
        <div class="mr-16 mt-4">
          <!-- <h3>Contact:</h3> -->
          <CoreRichText :document="data.contact" />
        </div>
        <div class="mr-16 mt-4">
          <!-- <h3>Address:</h3> -->
          <CoreRichText :document="data.address" />
        </div>
        <div class="mr-16 mt-4">
          <!-- <h3>Address:</h3> -->
          <CoreRichText :document="data.address2" />
        </div>
        <div class="mr-16 mt-4">
          <!-- <h3>Links:</h3> -->
          <div class="flex mt-2">
            <a :href="data.instagramUrl" target="_blank" class="mr-4">
              <CoreIcon class="h-5" icon="instagram" />
            </a>
            <a :href="data.linkedInUrl" target="_blank" class="mr-4">
              <CoreIcon class="h-5" icon="linkedin" />
            </a>
            <a :href="data.pintrestUrl" target="_blank" class="mr-4">
              <CoreIcon class="h-5" icon="pintrest" />
            </a>
            <a :href="data.facebookUrl" target="_blank" class="mr-4">
              <CoreIcon class="h-5" icon="facebook" />
            </a>
            <RouterLink class="link" :to="{ name: 'News' }">News</RouterLink>
          </div>
        </div>
      </div>
      <div class="flex flex-col justify-end ml-auto">
        <div class="flex justify-end">
          <img
            v-for="(img, i) in data.professionalBodies"
            class="w-12 h-logo"
            :key="i"
            :src="img.fields.file.url"
          />
        </div>
        <span v-text="data.ethicsStatement" />
      </div>
    </template>
  </footer>
</template>

<script>
import { getGlobal } from "@io";

export default {
  name: "BlockFooter",

  data: () => ({
    data: null
  }),

  async created() {
    this.data = await getGlobal();
  }
};
</script>

<style scoped>
.h-logo {
  height: min-intrinsic;
}
</style>
