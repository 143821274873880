<template>
  <span class="select-none logotype-letter-spacing">YVES FREY DIAMONDS</span>
</template>

<script>
export default {
  name: "CoreLogotype"
};
</script>

<style>
.logotype-letter-spacing {
  letter-spacing: 0.3em;
}
</style>
