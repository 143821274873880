<template>
  <div class="burger-container">
    <div class="burger" :class="{ open }" />
  </div>
</template>

<script>
export default {
  name: "CoreBurger",

  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.burger-container {
  height: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.bg-black .burger,
.bg-black .burger:before,
.bg-black .burger:after {
  background-color: white;
}

.bg-white .burger,
.bg-white .burger:before,
.bg-white .burger:after {
  background-color: black;
}

.burger,
.burger:before,
.burger:after {
  width: 2rem;
  height: 2px;
  transition: 0.3s background-color, 0.3s color;
}

.burger:before,
.burger:after {
  position: relative;
  display: block;
  content: "";
  transition: 0.3s transform cubic-bezier(0.22, 1, 0.36, 1);
}

.burger:before {
  transform-origin: bottom left;
  top: -0.7rem;
}

.burger:after {
  transform-origin: top left;
  top: calc(0.7rem - 1px);
}

.bg-black .burger.open {
  background-color: black;
}

.bg-white .burger.open {
  background-color: white;
}

.burger.open:before {
  transform: rotate(45deg);
}
.burger.open:after {
  transform: rotate(-45deg) translateY(-1px);
}
</style>
