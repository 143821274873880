import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@store";
import { WHITE_BG } from "@const";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch("setBackgroundWhite", WHITE_BG.includes(to.name));
  next();
});

export default router;
